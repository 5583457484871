<template>
  <div>
    <v-container>
      <v-row no-gutters align="start">
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0 pb-2"
          divider=">"
        ></v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn small depressed :to="{ name: 'module-melrose-properties-all' }"
          ><v-icon left>mdi-arrow-left</v-icon>Back to Properties</v-btn
        >
      </v-row>

      <v-card outlined class="mt-2">
        <v-toolbar flat color="grey lighten-3" height="auto">
          <v-list-item class="pl-0 pr-0" three-line>
            <v-row no-gutters>
              <v-col cols="7">
                <v-list-item-content>
                  <v-list-item-title class="title text-wrap">
                    {{ property.address.short }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-col>
              <v-col cols="5" class="text-right pt-3">
                <v-btn dark small depressed class="mr-2"> Right Move </v-btn>
                <v-btn dark small depressed class="mr-2"> Zoopla </v-btn>
                <v-btn dark small depressed class="mr-2"> On the Market </v-btn>
                <v-btn
                  dark
                  small
                  depressed
                  :loading="pdfLoading"
                  @click="downloadPdf"
                >
                  <v-icon left>mdi-file-pdf-box</v-icon>Download as PDF
                </v-btn>
              </v-col>
            </v-row>
          </v-list-item>

          <template v-slot:extension>
            <v-tabs dark background-color="grey darken-2">
              <v-tab
                :to="{
                  name: `module-melrose-properties-individual`,
                }"
                >Details</v-tab
              >
              <v-tab
                :to="{
                  name: `module-melrose-properties-individual-admin`,
                }"
                >Admin</v-tab
              >
              <v-tab
                :to="{
                  name: `module-melrose-properties-individual-descriptions`,
                }"
                >Descriptions</v-tab
              >
              <v-tab
                :to="{
                  name: `module-melrose-properties-individual-pdf`,
                }"
                >PDF</v-tab
              >
              <v-tab
                :to="{
                  name: `module-melrose-properties-individual-floor-plan`,
                }"
                >Floor Plan</v-tab
              >
              <v-tab
                :to="{
                  name: `module-melrose-properties-individual-images`,
                }"
                >Images/Videos</v-tab
              >
              <v-tab
                :to="{
                  name: `module-melrose-properties-individual-viewings`,
                }"
                >Viewings</v-tab
              >

              <v-tab
                :to="{
                  name: `module-melrose-properties-individual-noi`,
                }"
                >NOI's</v-tab
              >
              <v-tab
                :to="{
                  name: `module-melrose-properties-individual-offers`,
                }"
                >Offers</v-tab
              >
              <v-tab
                :to="{
                  name: `module-melrose-properties-individual-matching`,
                }"
                >Property Matching</v-tab
              >
            </v-tabs>
          </template>
        </v-toolbar>
        <v-divider></v-divider>
        <div class="pa-5">
          <router-view></router-view>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pdfLoading: false,
      breadcrumbs: [
        {
          text: "Properties",
          disabled: false,
          to: { name: "module-melrose-properties-all" },
          exact: true,
        },
      ],
    };
  },

  computed: {
    property() {
      return this.$store.getters["melrose/propertiesStore/get"];
    },
  },

  methods: {
    downloadPdf() {
      const appId = this.$route.params.id;

      const propertyId = this.$route.params.propertyId;
      this.pdfLoading = true;

      this.$store
        .dispatch("melrose/propertiesStore/downloadPropertyPdf", {
          appId,
          propertyId,
        })
        .then((response) => {
          let blob = new Blob([response.data], { type: "application/pdf" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "property.pdf";
          link.click();
          this.pdfLoading = false;
        })
        .catch(() => {
          this.pdfLoading = false;
        });
    },
  },

  created() {
    this.breadcrumbs.push({
      text: this.property.address.short,
      disabled: true,
    });
  },
};
</script>
