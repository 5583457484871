var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-row',{attrs:{"no-gutters":"","align":"start"}},[_c('v-breadcrumbs',{staticClass:"pa-0 pb-2",attrs:{"items":_vm.breadcrumbs,"divider":">"}}),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","depressed":"","to":{ name: 'module-melrose-properties-all' }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-left")]),_vm._v("Back to Properties")],1)],1),_c('v-card',{staticClass:"mt-2",attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"flat":"","color":"grey lighten-3","height":"auto"},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c('v-tabs',{attrs:{"dark":"","background-color":"grey darken-2"}},[_c('v-tab',{attrs:{"to":{
                name: `module-melrose-properties-individual`,
              }}},[_vm._v("Details")]),_c('v-tab',{attrs:{"to":{
                name: `module-melrose-properties-individual-admin`,
              }}},[_vm._v("Admin")]),_c('v-tab',{attrs:{"to":{
                name: `module-melrose-properties-individual-descriptions`,
              }}},[_vm._v("Descriptions")]),_c('v-tab',{attrs:{"to":{
                name: `module-melrose-properties-individual-pdf`,
              }}},[_vm._v("PDF")]),_c('v-tab',{attrs:{"to":{
                name: `module-melrose-properties-individual-floor-plan`,
              }}},[_vm._v("Floor Plan")]),_c('v-tab',{attrs:{"to":{
                name: `module-melrose-properties-individual-images`,
              }}},[_vm._v("Images/Videos")]),_c('v-tab',{attrs:{"to":{
                name: `module-melrose-properties-individual-viewings`,
              }}},[_vm._v("Viewings")]),_c('v-tab',{attrs:{"to":{
                name: `module-melrose-properties-individual-noi`,
              }}},[_vm._v("NOI's")]),_c('v-tab',{attrs:{"to":{
                name: `module-melrose-properties-individual-offers`,
              }}},[_vm._v("Offers")]),_c('v-tab',{attrs:{"to":{
                name: `module-melrose-properties-individual-matching`,
              }}},[_vm._v("Property Matching")])],1)]},proxy:true}])},[_c('v-list-item',{staticClass:"pl-0 pr-0",attrs:{"three-line":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"7"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"title text-wrap"},[_vm._v(" "+_vm._s(_vm.property.address.short)+" ")])],1)],1),_c('v-col',{staticClass:"text-right pt-3",attrs:{"cols":"5"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"dark":"","small":"","depressed":""}},[_vm._v(" Right Move ")]),_c('v-btn',{staticClass:"mr-2",attrs:{"dark":"","small":"","depressed":""}},[_vm._v(" Zoopla ")]),_c('v-btn',{staticClass:"mr-2",attrs:{"dark":"","small":"","depressed":""}},[_vm._v(" On the Market ")]),_c('v-btn',{attrs:{"dark":"","small":"","depressed":"","loading":_vm.pdfLoading},on:{"click":_vm.downloadPdf}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-file-pdf-box")]),_vm._v("Download as PDF ")],1)],1)],1)],1)],1),_c('v-divider'),_c('div',{staticClass:"pa-5"},[_c('router-view')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }